<template>
  <button class="flex-none group relative isolate flex items-center rounded-lg px-2 py-0.5 text-[0.8125rem]/6 font-medium text-white/30 transition-colors hover:text-sky-300 gap-x-2 cursor-pointer" @click.prevent="downloadFile(type, category, format)">
    <span class="absolute inset-0 -z-10 scale-75 rounded-lg bg-white/5 opacity-0 transition group-hover:scale-100 group-hover:opacity-100"></span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentcolor" class="size-4">
      <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
      <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
    </svg>
    <span class="self-baseline text-white">{{ buttonText }}</span>
  </button>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    type: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  methods: {
    downloadFile(type, category, format) {
      this.$emit('download-file', { type, category, format });
    }
  }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>